import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WallOfSoundsComponent} from './wall-of-sounds/wallOfSounds.component';
import {HomeComponent} from './home.component';
import {ListenComponent} from './listen/listen.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'listen',
    component: ListenComponent
  },
  {
    path: 'sounds',
    component: WallOfSoundsComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
