import {Component} from '@angular/core';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./app.scss']
})
export class HomeComponent {

  constructor() {
  }

}
