<div class="title-container">

  <span class="main-title"><a routerLink="/" routerLinkActive="active">Sigma 7</a></span>

  <!-- <span class="main-nav">>&nbsp;&nbsp;<a routerLink="/listen" routerLinkActive="active">Listen</a></span> -->
  <span class="main-nav">|&nbsp;&nbsp;<a routerLink="/sounds" routerLinkActive="active">Wall Of Sounds</a></span>

</div>

<router-outlet></router-outlet>
