<div>
  Use this fun feature to re-create Sigma 7 broadcasts in your own home!
</div>

<div class="board-selector-container" *ngIf="board">

  <button
    *ngFor="let wall of board.walls; index as i"
    (click)="changeWall(i)"
    [ngClass]="{ 'board-change-selected': selectedWall === wall, 'board-change-deselected': selectedWall !== wall }"
    >
    {{wall.title}}

  </button>

</div>

<div class="audio-button-container" *ngIf="selectedWall">

  <audio-button *ngFor="let sound of selectedWall.sounds" [sound]="sound">Piss</audio-button>

</div>
