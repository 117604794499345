import {Component, Input, OnInit} from '@angular/core';
import {Sound} from '../domain/Sound';

@Component({
  selector: 'audio-button',
  templateUrl: './audioButton.component.html',
  styleUrls: ['../../app.scss']
})
export class AudioButtonComponent implements OnInit {

  @Input()
  sound: Sound;

  audioElement: HTMLAudioElement;
  caption: string;
  touchMode = false;
  loaded = false;
  playing = false;

  ngOnInit(): void {
    this.audioElement = new Audio();

    this.setCaption();
    this.addEventListeners();

    this.audioElement.src = this.sound.url;
    this.audioElement.preload = 'auto';
    this.audioElement.load();
  }

  onMouseDown(): void {
    if (!this.touchMode) {
      this.play();
    }
  }

  onTouchStart(): void {
    if (!this.touchMode) {
      this.touchMode = true;
    }
    this.play();
  }

  play(): void {
    if (this.audioElement.currentTime !== 0 && !this.audioElement.ended) {
      this.restart();
    } else {
      this.audioElement.play();
    }
  }

  private setCaption(): void {
    if (this.sound.title) {
      this.caption = this.sound.title;
    } else {
      this.caption = this.sound.url.substr(this.sound.url.lastIndexOf('/') + 1);
      this.caption = this.caption.substr(0, this.caption.indexOf('.'));
    }
  }

  private addEventListeners(): void {
    this.audioElement.addEventListener('play', () => {
      this.playing = true;
    });

    this.audioElement.addEventListener('ended', () => {
      this.playing = false;
    });

    this.audioElement.addEventListener('loadeddata', () => {
      this.loaded = true;
    });

  }

  private restart(): void {
    this.audioElement.currentTime = 0;
  }

}
