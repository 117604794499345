<div>Here are 3 ways to listen to Sigma 7 FM!

<h2>Web (recommended)</h2>
<div>
    Click
    <a href="https://static.sigma7.biz/player" target="_blank">here</a>
    to open the web player.
</div>

<h2>Streaming (for advanced users)</h2>
<p>
<div>
    For the direct stream (e.g. for Sonos), use
    <a href="http://shaincast.caster.fm:32608/listen.mp3?authn33e54d596a0ab9d02cbc054b04439328">this link</a>.
</div>

<h2>Old school</h2>
<p>
<div>
    You can listen via the
    <a href="https://sigma7fm.caster.fm" target="_blank">Caster website</a>,
    though it's full of ads, so I wouldn't recommended it.
</div>
