import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AudioButtonComponent} from './wall-of-sounds/audio-button/audioButton.component';
import {WallOfSoundsComponent} from './wall-of-sounds/wallOfSounds.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home.component';
import {WallOfSoundsService} from './wall-of-sounds/wallOfSounds.service';
import {HttpClientModule} from '@angular/common/http';
import { ListenComponent } from './listen/listen.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WallOfSoundsComponent,
    AudioButtonComponent,
    ListenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    WallOfSoundsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
