import {Component, OnInit} from '@angular/core';
import {Board} from './domain/Board';
import {WallOfSoundsService} from './wallOfSounds.service';
import {Wall} from './domain/Wall';

@Component({
  templateUrl: './wallOfSounds.component.html',
  styleUrls: ['../app.scss']
})
export class WallOfSoundsComponent implements OnInit {

  board: Board;
  selectedWall: Wall;

  constructor(private service: WallOfSoundsService) {
  }

  ngOnInit(): void {
    this.service.getBoard('board1').subscribe((board: Board) => {
      this.board = board;
      this.selectedWall = this.board.walls[0];
    });
  }

  changeWall(index: number): void {
    this.selectedWall = this.board.walls[index];
  }

}
