import {Board} from './domain/Board';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class WallOfSoundsService {

  constructor(private http: HttpClient) {
  }

  public getBoard(id: string): Observable<Board> {
    return this.http.get<Board>(`assets/sound_boards/${id}.json`);
  }

}
